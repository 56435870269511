/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

// import AppIcon from '@assets/customizedIconSet/AppIcon.svg';
import styled from '@emotion/styled';
import { Input } from 'antd';
import kebabCase from 'lodash/kebabCase';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Image from 'next/image';
import Link from 'next/link';
import { IoClose, IoHomeOutline, IoSearch } from 'react-icons/io5';
import { useDebouncedCallback } from 'use-debounce';

import AccessUnavailable from '@assets/customizedIconSet/apps/AccessUnavailable.svg';
import DeliveryOrderIcon from '@assets/customizedIconSet/apps/DeliveryOrder.svg';
import FreightForwardingIcon from '@assets/customizedIconSet/apps/FreightForwarding.svg';
import WaveBackground from '@assets/customizedIconSet/apps/WaveBackground.svg';
import KoiReaderEmblem from '@assets/KoiReaderEmblem.svg';
import { homePageFiltersVar } from '@common/apollo/reactiveVars';
import { initialFilters } from '@common/components/home/utils';
import PageHeader from '@components/elements/PageHeader';
import AppLayout from '@components/layouts/appLayout';
import { useModal } from '@hooks';
import { subscribedDashboardAppsVar } from 'src/apollo/reactiveVars';
import { KOIREADER_SUPPORT_EMAIL } from 'src/global/constants';

// Third party logos
import Cargowise from '/public/third-party-logos/Cargowise.png';
import Sap from '/public/third-party-logos/Sap.png';
import Oracle from '/public/third-party-logos/Oracle.png';
import Compcare from '/public/third-party-logos/Compcare.png';
import BlueYonder from '/public/third-party-logos/BlueYonder.png';
import Descartes from '/public/third-party-logos/Descartes.png';
import Magaya from '/public/third-party-logos/Magaya.png';
import MicrosoftDynamics from '/public/third-party-logos/MicrosoftDynamics.png';

const THIRD_PARTIES = [
  {
    id: 'cargowise',
    logoSrc: Cargowise,
  },
  {
    id: 'sap',
    logoSrc: Sap,
  },
  {
    id: 'oracle',
    logoSrc: Oracle,
  },
  {
    id: 'compcare',
    logoSrc: Compcare,
  },
  {
    id: 'blueYonder',
    logoSrc: BlueYonder,
  },
  {
    id: 'descartes',
    logoSrc: Descartes,
  },
  {
    id: 'magaya',
    logoSrc: Magaya,
  },
  {
    id: 'microsoftDynamics',
    logoSrc: MicrosoftDynamics,
  },
];

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-size: 1.25rem;
  background: ${(props) => props.theme.color.primary600};
`;

const StyledPage = styled.section`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;

  .background {
    position: absolute;
    width: 50%;
    height: auto;
    opacity: 1;
    top: 0;
    right: 0;
    pointer-events: none;
  }
`;

const AllAppsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const StyledAppsSection = styled.section`
  h1 {
    font-size: 1em;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  .apps {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }
`;

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${(props) => props.theme.color.gray500};
  background: ${(props) => props.theme.color.primary600};
  height: 10rem;
  aspect-ratio: 1;
  padding: 1.5rem;
  text-align: center;
  line-height: 1.2;
  border: 2px solid ${(props) => props.theme.color.primary300};
  transition: 300ms ease;

  &:hover {
    background: ${(props) => props.theme.color.primary300};
  }

  svg {
    width: 4rem;
    fill: ${(props) => props.color};
  }

  span {
    font-size: 1em;
    font-weight: 600;
  }
`;

const StyledTitle = styled.h2`
  font-size: 1.4em;
  margin: 0;
`;

const StyledSearchInput = styled(Input)`
  width: 40%;
  max-width: 40rem;
  height: 2.5em;
  color: ${(props) => props.theme.color.gray800};
  background: ${(props) => props.theme.color.primary200};
  border: none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  .ant-input {
    background: ${(props) => props.theme.home.inputBg};
    color: ${(props) => props.theme.color.gray800};
    border: none;
    height: 100%;
    font-size: 1.1em;
    padding: 0.5em 1em;
    padding-bottom: 0.4em;
    box-shadow: none;
  }

  .ant-input-suffix {
    cursor: pointer;
  }
  .ant-input-group-addon {
    background: ${(props) => props.theme.home.inputBg};
    .ant-input-search-button {
      background: ${(props) => props.theme.home.inputBg};
      border: none;
      height: 100%;
      font-size: 1.1em;
      padding-right: 0.5em;
      box-shadow: none;
      .anticon-search {
        color: ${(props) => props.theme.color.gray800};
      }
    }
  }
`;

const StyledFooter = styled.div`
  width: 100%;
  margin-top: auto;
  background: ${(props) => props.theme.color.primary200};

  .title {
    position: relative;
    background: ${(props) => props.theme.color.primary800};
    padding: 0.7rem 3rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    width: 60%;
    z-index: 0;

    span {
      font-size: 1.1em;

      &:first-of-type {
        color: ${(props) => props.theme.color.accent700};
      }
    }

    .accent-design {
      position: absolute;
      z-index: -1;
      width: calc(100% + 3rem);
      height: 100%;
      top: 0;
      left: 0;
      transform: skew(-50deg);
      background: inherit;
      border-right: 2px solid ${(props) => props.theme.color.accent700};
      border-bottom: 1px solid ${(props) => props.theme.color.accent700};

      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        right: -8rem;
        width: 8rem;
        height: 1px;
        color: ${(props) => props.theme.color.accent700};
        background: currentColor;
      }

      &:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: -0.45rem;
        right: -9rem;
        height: 1rem;
        width: 1rem;
        transform: skew(50deg);
        border-radius: 50%;
        border: 1px solid ${(props) => props.theme.color.accent700};
      }
    }
  }

  .third-parties {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    width: min(75%, 1200px);
    padding: 1rem 3rem;
  }
`;

// Props that need to be passed to AppLayout.
const SidebarProps = {
  logo: <KoiReaderEmblem />,
  buttons: [
    {
      icon: <IoHomeOutline />,
      title: 'Home',
      link: '/',
      onClick: () => null,
    },
  ],
};

const Index = (): JSX.Element => {
  const { t: common } = useTranslation();
  const allAppsDetails = [
    {
      title: 'Document Digitalization',
      apps: [
        {
          id: 'deliveryOrder',
          name: 'Delivery Order',
          icon: <DeliveryOrderIcon />,
          isSubscribed: subscribedDashboardAppsVar().includes('deliveryOrder'),
          color: '#60E4FF',
        },
        {
          id: 'freightForwarding',
          name: 'Freight Forwarding',
          icon: <FreightForwardingIcon />,
          isSubscribed:
            subscribedDashboardAppsVar().includes('freightForwarding'),
          color: '#EACC0E',
        },
      ],
    },
    // {
    //   title: 'Warehouse Automation',
    //   apps: [
    //     {
    //       id: 'cartAudit',
    //       name: 'Cart Audit',
    //       icon: <DeliveryOrderIcon />,
    //       isSubscribed: false,
    //       color: '#60E4FF',
    //     },
    //     {
    //       id: 'epod',
    //       name: 'EPOD',
    //       icon: <FreightForwardingIcon />,
    //       isSubscribed: false,
    //       color: '#EACC0E',
    //     },
    //     {
    //       id: 'replenishment',
    //       name: 'Replenishment',
    //       icon: <DeliveryOrderIcon />,
    //       isSubscribed: false,
    //       color: '#60E4FF',
    //     },
    //     {
    //       id: 'qrBarcodeScan',
    //       name: 'QR/Barcode Scan',
    //       icon: <FreightForwardingIcon />,
    //       isSubscribed: false,
    //       color: '#EACC0E',
    //     },
    //   ],
    // },
    // {
    //   title: 'Smart Yards / Ports',
    //   apps: [],
    // },
  ];

  const [searchValue, setSearchValue] = React.useState('');
  const debouncedSearchCallback = useDebouncedCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
    },
    300
  );

  const { modal } = useModal();

  React.useEffect(() => {
    homePageFiltersVar(initialFilters);
  }, []);

  return (
    <AppLayout sidebarProps={SidebarProps}>
      <HomeContainer>
        <PageHeader icon={<IoHomeOutline />} title="Apps" titleLink={'/'} />
        <StyledPage>
          <WaveBackground className="background" />
          <StyledTitle>All Apps</StyledTitle>
          <StyledSearchInput
            placeholder="Search"
            defaultValue={searchValue}
            onChange={debouncedSearchCallback}
            suffix={
              searchValue.length > 0 ? (
                <IoClose onClick={() => setSearchValue('')} />
              ) : (
                <IoSearch />
              )
            }
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={true}
          />
          <AllAppsContainer>
            {allAppsDetails
              .filter((section) =>
                section.apps.some((app) =>
                  app.name.toLowerCase().includes(searchValue.toLowerCase())
                )
              )
              .map((section) => {
                return (
                  <StyledAppsSection key={section.title}>
                    <h1>{section.title}</h1>
                    <div className="apps">
                      {section.apps
                        .filter((app) =>
                          app.name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                        )
                        .map((app) =>
                          app.isSubscribed ? (
                            <Link
                              href={`/apps/${kebabCase(app.id)}?tab=home`}
                              key={app.id}
                              passHref
                            >
                              <StyledApp color={app.color}>
                                {app.icon}
                                <span>{app.name}</span>
                              </StyledApp>
                            </Link>
                          ) : (
                            <StyledApp
                              color={app.color}
                              key={app.id}
                              onClick={() =>
                                modal({
                                  title: 'Access Unavailable',
                                  text: common('info.getAppAccess'),
                                  type: 'error',
                                  okText: 'Contact Support',
                                  cancelText: 'Cancel',
                                  centered: true,
                                  icon: <AccessUnavailable />,
                                  onOk: () => {
                                    window.location.href = `mailto:${KOIREADER_SUPPORT_EMAIL}`;
                                  },
                                  onCancel: () => null,
                                })
                              }
                            >
                              {app.icon}
                              <span>{app.name}</span>
                            </StyledApp>
                          )
                        )}
                    </div>
                  </StyledAppsSection>
                );
              })}
          </AllAppsContainer>
        </StyledPage>
        <StyledFooter>
          <div className="title">
            <span>KoiConnect</span>
            <span>Connectivity with 10+ Platforms</span>
            <div className="accent-design"></div>
          </div>
          <div className="third-parties">
            {THIRD_PARTIES.map((thirdParty) => (
              <Image
                src={thirdParty.logoSrc}
                key={thirdParty.id}
                alt={thirdParty.id}
              />
            ))}
          </div>
        </StyledFooter>
      </HomeContainer>
    </AppLayout>
  );
};

export async function getStaticProps({ locale }: any): Promise<any> {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}

export default Index;
